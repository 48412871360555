import React from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";

const LegalInfo = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          // don't show the menu on this page
          headerClassName: "site-header--menu-none",
          headerButton: (
            <>
              <Link
                to="/#"
                className="element btn bg-gradient-1 btn-text-white header-btn rounded-5"
              >
                Zum Erstgespräch
              </Link>
            </>
          ),
        }}
      >
        <div className="pt-30 pb-9 pb-md-16 pb-lg-21">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-11">
                <div className="text-center">
                  <h2 className="font-size-11 mb-8">Rechtliches</h2>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">Nutzungsbedingungen</h5>
                    <a
                      href="/docs/Nutzungsbedingungen_konzierge.pdf"
                      target="_blank"
                      // rel="noopener noreferrer"
                      className="font-size-5 heading-default-color mb-0"
                    >
                      Nutzungsbedingungen konzierge
                    </a>
                  </div>
                  <div className="pt-lg-17 pt-8">
                    <h5 className="font-size-7 mb-7">Datenschutzhinweise</h5>
                    <div>
                      <a
                        href="/docs/Datenschutzhinweise_konzierge_Website.pdf"
                        target="_blank"
                        // rel="noopener noreferrer"
                        className="font-size-5 heading-default-color mb-2"
                      >
                        Datenschutzhinweise Website
                      </a>
                    </div>
                    <div>
                      <a
                        href="/docs/Datenschutzhinweise_konzierge_App.pdf"
                        target="_blank"
                        // rel="noopener noreferrer"
                        className="font-size-5 heading-default-color mb-0"
                      >
                        Datenschutzhinweise konzierge
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};
export default LegalInfo;
